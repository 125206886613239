import pdfMake from 'pdfmake';
import handlePurchase from "@/services/modules/purchase";
import { chunkArray } from '@/services/utils/global';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import handlePurchaseAndSales from "@/services/modules/purchase";
const { format, parseISO } = require('date-fns');
const {fetchProductStock} = handlePurchaseAndSales();
const requisitionPdfPrinter = () => {

    const { formatCurrency, currencyToWord } = handlePurchase();

    const exportToPDF = (company, requisition, barcode, qrcode, userName) => {
        const tableItems = requisition.has_item_detail ? requisition.general : requisition.account;


        var doc = {
            pageSize: 'A4',
            pageMargins: [ 30, 100, 30, 70 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(requisition, barcode),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10
            },
            info: {
                title: requisition.requisition_number
            }
        }

        pushIntoTable(doc, requisition, tableItems, company.id)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const dateTimeFormat = (inputDate) => {
        const date = parseISO(inputDate);

        const hasTimeInformation = date.getHours() !== 0 || date.getMinutes() !== 0 || date.getSeconds() !== 0;
        const formatString = hasTimeInformation ? 'dd-MMM-yy h:mm aa' : 'dd-MMM-yy';
        return format(date, formatString).replace(/(am|pm)/i, (match) => match.toUpperCase());
    }
    const formatDate = (date) => {
            var formattedDate = date.toISOString().split('T')[0];
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var amOrPm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

            return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }
    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }
    const getHeader = (company) => {
            return {
                margin: [ 30, 10, 30, 10 ],
                columns: [
                    {
                        alignment: 'left',
                        image: company.logo64,
                        maxHeight: 60
                    },

                    {
                        alignment: 'right',
                        stack: [
                            company.name,
                            'Address: ' + company.address,
                            'Phone: ' + company.phone,
                            'E-mail: ' + company.email
                        ]
                    }
                ]

            }
    }
    const getContent = (requisition, barcode) => {
            
            return  [
                {
                    text: 'Requisition',
                    style: 'header',
                    alignment: 'center',
                    margin: [0, 0, 20, 10],
                    bold: true,
                    color: 'black',
                },

                {
                    margin: [0, 20, 0, 0],
                    alignment: 'justify',
                    columns: [
                        {
                            width: '50%',
                            stack: [
                                {
                                    text: [
                                        {
                                            text: 'Requisition from: ',
                                            bold: true,
                                            color: 'black',
                                        },

                                        {
                                            text: requisition.user ? requisition.user.name : '',
                                        }
                                    ]
                                },
                                {
                                    text: [
                                        {
                                            text: requisition.project && requisition.project.name ? 'Project Details: ' : '',
                                            bold: true,
                                            color: 'black',
                                        },

                                        {
                                            text: requisition.project ? requisition.project.name : '',
                                        }
                                    ]
                                },
                                {
                                    text: [
                                        {
                                            text: requisition.business ? 'Business Unit: ' : '',
                                            bold: true,
                                            color: 'black',
                                        },

                                        {
                                            text: requisition.business ? requisition.business.name : '',
                                        }
                                    ]
                                },
                            ]

                        },


                        {
                            alignment: 'right',
                            stack: [
                                {
                                    text: [
                                        {
                                            text: 'Requisition Date: ',
                                            bold: true,
                                            margin: [0, 0, 10, 0]
                                        },

                                        {
                                            text: formatToDdMmYy(requisition.date),
                                        }
                                     ]
                                },

                                {
                                    text: [
                                        {
                                            text: 'Requisition No: ',
                                            bold: true,
                                            margin: [0, 0, 10, 0]
                                        },

                                        {
                                            text: requisition.requisition_number,
                                        }
                                 ]

                                },

                                {
                                    svg: barcode
                                },

                            ]
                        },
                    ]
                },

                {
                    style: 'tableExample',
                    margin: [0, 10, 0, 0],
                    table: {
                        widths: ['20%', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '20%'],
                        body: []
                    },
                    layout: {
				        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex === 0) ? '#f3f2f7' : null;
                        },
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i, node) {
                            return 0.5;
                        }
			        }
		        },
                ...formatSignatureData(requisition)
            ]
    }
    const getFooter = (userName, qrcode) =>{
            return {
                margin: [ 30, 0, 30, 0 ],
                columns: [
                    {
                        columns: [
                            {
                                svg: qrcode
                            },

                            {
                                width: '90%',
                                alignment: 'left',
                                text: 'N.B: This is a system generated documents and requires no manual signature.',
                                margin: [5, 55, 0, 0],
                                fontSize: 8
                            }
                        ]

                    },

                    {
                        width: '20%',
                        alignment: 'right',
                        fontSize: 8,
                        margin: [0, 35, 0, 0],
                        stack: [
                            {
                                text: 'Printed by',
                                bold: true
                            },
                            {
                                text: userName
                            },
                            {
                                text: formatDate(new Date())
                            }
                        ]
                    }
                ]
            }
    }
    const formatSignatureData = (requisition) => {
        
        let signatureData = [
            getSingleSignature('Prepared By', (requisition.user ? requisition.user.name : '' ), requisition.created_at),
        ];

        const signatures = requisition.workflow_approval_logs.map(log => {
            return getSingleSignature(log.workflow_general.approval_step.name, log.user.name, log.created_at)
        })

        signatureData = signatureData.concat(signatures);

        const chunkedData = chunkArray(signatureData, 4);
        let topHeight = 610;

        return chunkedData.map(signatures => {
            topHeight += 60;
            return {
                alignment: 'justify',
                absolutePosition: { x: 30, y: topHeight },
                columns: signatures
            }
        })
    }
    const getSingleSignature = (title, name, date) => {
        return {
            width: '25%',
            stack: [
                {
                    text: `${title}: `,
                    color: 'black',
                    bold: true,
                },
                {
                    text: name ,
                    alignment: 'left',
                    width: '50%'
                },
                {
                    text: dateTimeFormat(date) ,
                    alignment: 'left',
                    width: '50%'
                },
            ]
        }
    }
    const pushIntoTable = (doc, requisition, tableItems, company_id) => {

            doc.content[2].table.body.push(getTableHeader());

            tableItems.forEach(item => {
                const rowData = getRowData(requisition, item, company_id);

                doc.content[2].table.body.push(rowData);
            });
        return doc.content[2].table.body;
    }
    const getTableHeader = () => {
        return [
            { text: 'PARTICULARS' },
            { text: 'Unit' },
            { text: 'Unit Price' },
            { text: 'Total BOQ QTY', },
            { text: 'Remaining BOQ QTY' },
            { text: 'Required QTY' },
            { text: 'Stock in hand' },
            { text: 'Required Date' }
        ];
    }
    const getRowData = (requisition, item, company_id) => {

        let rowForInvoice = [
            {
                stack: [
                    {
                        text: (requisition.has_item_detail ? item.product.name : item.account_head.name),
                        bold: true,
                    },
                    {
                        text: (item.description ? item.description : '') ,
                    },
                ]
            },
            { text: requisition.has_item_detail ? item?.product?.description?.unit?.name : '', alignment: 'right' },
            { text: requisition.has_item_detail ? formatCurrency(item.rate) : '', alignment: 'right' },
            { text: formatCurrency(item.total_boq_qty), alignment: 'right' },
            { text: formatCurrency(item.remaining_boq_qty), alignment: 'right' },
            { text: requisition.has_item_detail && item.quantity ? formatCurrency(item.quantity) : '', alignment: 'right' },
            { text: formatCurrency(item.stock_note), alignment: 'right' },
            { text: item.formatted_expected_delivery_date ?? '', alignment: 'right' }
        ]

        return rowForInvoice;
    }

    const getStock = (id, company_id) => {
        let productStock = null;
        let query = `?company_id=${company_id}`;

        fetchProductStock(id, query).then(res => {
            if(res.status) {
                productStock = res.data.stock;
            }
        })
        return productStock;
    }

    return {
        exportToPDF
    }
}

export default requisitionPdfPrinter;
